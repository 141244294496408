<template>
  <div class="artistic-wrap">
    <headNav active="1"></headNav>
    <div class="artistic-conents w1200 tal">
      <div class="title">
        <img src="../assets/artistic/artistic-titleEn.png" alt="" style="width: 235px;" />
        <div v-if="$i18n.locale == 'zh'" class="title-t">
          艺术顾问
        </div>
      </div>
      <div class="item flex between">
        <div class="text flex column h-center">
          <p v-if="$i18n.locale == 'zh'">
            著名作曲家，1970年毕业于西安音乐学院作曲系，1978年入中央音乐学院作曲系进修，是国务院有突出贡献的专家、全国文化系统先进工作者，中国音乐家协会名誉主席。曾任中国文学艺术界联合会第十届荣誉委员，中国音乐家协会主席，陕西省文学艺术界联合会主席，陕西省歌舞剧院院长，陕西省戏曲研究院副院长，陕西省电影音乐学会会长，西安音乐学院院长。
          </p>
          <p>
            Mr. Zhao Jiping is a famous composer who graduated from the
            Composition Department of Xi'an Conservatory of Music in 1970. He
            furthered his studies at the Composition Department of Central
            Conservatory of Music in 1978, and is an expert with outstanding
            contributions assessed by the State Council as well as an advanced
            worker of the national cultural system and Honorary Chairman of
            Chinese Musicians Association. He once served as a 10th honorary
            member of Chinese Federation of Literary and Art Circles, Chairman
            of Chinese Musicians Association, Chairman of Shaanxi Federation of
            Literary and Art Circles, Director of Shaanxi Song and Dance
            Theatre, Vice President of Shaanxi Opera Research Institute,
            President of Shaanxi Society of Film and Music, and Dean of Xi'an
            Conservatory of Music.
          </p>
        </div>
        <div class="pic" style="margin-right: -30px">
          <img src="../assets/artistic/artistic-pic1.png" alt="" style="width: 537px;height: 606px;" v-if="$i18n.locale == 'zh'" />
          <img src="../assets/artistic/artisticEn-pic1.png" alt="" style="width: 537px;height: 560px;margin-left: 30px;" v-else />
        </div>
      </div>

      <div class="item flex between">
        <div class="pic">
          <img src="../assets/artistic/artistic-pic2.png" alt="" style="width: 540px;height: 656px;margin-left: -78px" v-if="$i18n.locale == 'zh'" />
          <img src="../assets/artistic/artisticEn-pic2.png" alt="" style="width: 520px;height: 570px;margin-right: 30px;" v-else />
        </div>
        <div class="text flex column h-center">
          <p v-if="$i18n.locale == 'zh'">
            第十届、十一届全国政协委员，国家级优秀专家，享受国务院特殊津贴，中国文联德艺双馨艺术家。现任中国民族管弦乐学会会长、中国歌剧研究会副主席。曾任黑龙江省文化厅副厅长、中央歌剧院院长等职务。
          </p>
          <p>
            Mr. Liu Xijin is a member of the 10th and 11th national committee of
            CPPCC, a national outstanding expert enjoying special allowance by
            the State Council, as well as an artist with both professional
            skills and moral integrity recognised by the China Federation of
            Literary and Art Circles. Currently, he is President of China
            Nationalities Orchestra Society and Vice President of China Opera
            Research Society. He used to serve as Deputy Director of Culture
            Department of Heilongjiang Province and Director of China National
            Opera House.
          </p>
        </div>
      </div>

      <div class="item flex between">
        <div class="text flex column h-center">
          <p  v-if="$i18n.locale == 'zh'">
            民族音乐活动家，中国广播艺术团原团长、党委书记，中国民族管弦乐学会第五届副会长，第六届副会长兼秘书长。曾多次成功策划并率中国广播艺术团属下的中国广播民族乐团、曲艺团、交响乐团赴海内外巡演数百场，受到热烈欢迎。
          </p>
          <p>
            Mr. Wang Shuwei is a national music activist, former President and
            Secretary of the Party Committee of China Broadcasting and Art
            Troupe, 5th Vice President and 6th Vice President cum Secretary-
            General of China Nationalities Orchestra Society. He has
            successfully planned and led China Broadcasting Chinese Orchestra,
            Chinese folk art troupe and symphony orchestra under the China
            Broadcasting and Art Troupe to carry out hundreds of performing
            tours at home and abroad, which were warmly welcomed.
          </p>
        </div>
        <div class="pic" style="margin-right: -25px">
          <img src="../assets/artistic/artistic-pic3.png" alt="" style="width: 530px;height: 588px;" v-if="$i18n.locale == 'zh'" />
          <img src="../assets/artistic/artisticEn-pic3.png" alt="" style="width: 530px;height: 588px;margin-left: 30px;" v-else />
        </div>
      </div>

      <div class="item flex between">
        <div class="pic">
          <img src="../assets/artistic/artistic-pic4.png" alt="" style="width: 599px;height: 656px;margin-left: -141px" v-if="$i18n.locale == 'zh'" />
          <img src="../assets/artistic/artisticEn-pic4.png" alt="" style="width: 460px;height: 500px;margin-right: 30px;" v-else />
        </div>
        <div class="text flex column h-center">
          <p  v-if="$i18n.locale == 'zh'">
            国家一级作曲。历任上海民族乐团团长、艺术总监，中国民族管弦乐学会副会长，中国音乐家协会理事，民族音乐委员会副主任，中国民族管弦乐学会荣誉会长。享受中国国务院颁发的政府特殊津贴。
          </p>
          <p>
            Mr. Gu Guanren is a national first-grade composer who has served
            successively as President and Artistic Director of Shanghai Chinese
            Orchestra, Vice President of China Nationalities Orchestra Society,
            Director of Chinese Musicians Association, Vice Director of National
            Music Committee, and Honorary President of China Nationalities
            Orchestra Society. He enjoys the special government allowance
            granted by the State Council of China.
          </p>
        </div>
      </div>
    </div>
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
export default {
  components: { headNav, footNav },
};
</script>

<style scoped lang="scss">
.artistic-wrap {
  background-color: #f2f6f7;
}

.artistic-conents {
  .title {
    padding: 130px 0 40px;
    .title-t {
      font-size: 40px;
      margin-top: 20px;
      font-weight: 300;
      letter-spacing: 3px;
    }
  }
  .item {
    color: #303133;
    padding-bottom: 40px;
    p {
      font-size: 15px;
      color: #303133;
      line-height: 200%;
    }
  }
}
</style>
